<script>
import ProgressLine from "@/views/bonusSystem/components/progress/ProgressLine.vue";
import displaySettings from "@/mixin/displaySettings";
import ProgressLineCheckpoints from "@/views/bonusSystem/components/progress/ProgressLineCheckpoints.vue";
import MissionIconType from "@/components/app/bonusSystem/MissionTypes/MissionIconType.vue";

export default {
    name: "MissionCard",
    components: {MissionIconType, ProgressLineCheckpoints, ProgressLine},
    props: ['item'],
    mixins: [displaySettings],
    methods: {
        getProductTypeClass(id) {
            switch (id) {
                case 1:
                    return 'product-type-osago'
                case 3:
                    return 'product-type-green-card'
                case 5:
                    return 'product-type-tourism'
                default:
                    return null
            }
        },
        getProductTypeLabel(id) {
            switch (id) {
                case 1:
                    return 'ОСЦПВ'
                case 3:
                    return 'ЗК'
                case 5:
                    return 'Туризм'
                default:
                    return null
            }
        },
        getPeriodicityClass(id) {
            //TODO if need more colors
            switch (id) {
                case 0:
                    return 'bg-one-time'
                case 3:
                    return 'bg-cumulative'
                default:
                    return ''
            }
        },
        getPeriodicityCyclical(id) {
            switch (id) {
                case 1:
                    return moment().daysInMonth() - moment().format('D') + 'д';
                case 2:
                    return 'Квартал'
                default:
                    return null
            }
        }
    }
}
</script>

<template>
    <div class="mission-card card-shadow">
        <el-card class="card-shadow" :class="item.level_id > this.$store.getters.USER_INFO.levelId ? 'mission-block' : item.mission_progress >= 100 || item.mission_checkpoint_progress >= 100 ? 'mission-done' : ''" shadow="never">
            <div v-if="item.level_id > this.$store.getters.USER_INFO.levelId" class="mission-name pr-2 pl-2">
                <span>Заблоковано</span>
                <img src="~@/assets/images/bonus-system/mission-block.svg" alt="">
            </div>
            <div v-else-if="item.mission_progress >= 100 || item.mission_checkpoint_progress >= 100" class="mission-name pr-2 pl-2">
                <span >Виконано</span>
                <img src="~@/assets/images/bonus-system/mission-done.svg" alt="">
            </div>
            <div v-else
                 class="mission-name pr-2 pl-2"
                 :class="getPeriodicityClass(item.periodicity_id)"
            >
                <span>{{ item.periodicity }}</span>
            </div>
            <div v-if="isMobile && item.checkpoints.length && !item.amount && item.checkpoints[0].product_type_id"
                 class="pr-2 pl-2"
                 :class="getProductTypeClass(item.checkpoints[0].product_type_id)"
            >
                <span>{{ getProductTypeLabel(item.checkpoints[0].product_type_id) }}</span>
            </div>
            <div class="cycle" v-if="getPeriodicityCyclical(item.periodicity_id)">
                <div class="description">
                    <div class="other d-flex align-items-end">
                        <img src="~@/assets/images/icons/delivery-time.svg">
                        <span>{{ getPeriodicityCyclical(item.periodicity_id) }}</span>
                    </div>
                </div>
            </div>
            <el-row type="flex" align="middle" justify="space-between" :gutter="10" class="mt-2">
                <el-col :span="6" v-if="!isMobile">
                    <el-col v-if="item.checkpoints.length === 1 && item.checkpoints[0].product_type_id"
                            class="d-flex justify-content-center"
                    >
                        <el-col
                                :span="24"
                                class="text-center mb-1 w-50"
                                :class="getProductTypeClass(item.checkpoints[0].product_type_id)"
                        >
                            <span>{{ getProductTypeLabel(item.checkpoints[0].product_type_id) }}</span>
                        </el-col>
                    </el-col>
                    <el-col v-else-if="item.checkpoints.length && !item.amount && item.checkpoints[0].product_type_id"
                        class="d-flex justify-content-center"
                    >
                        <el-col
                                :span="24"
                                class="text-center mb-1 w-50"
                                :class="getProductTypeClass(item.checkpoints[0].product_type_id)"
                        >
                            <span>{{ getProductTypeLabel(item.checkpoints[0].product_type_id) }}</span>
                        </el-col>
                    </el-col>

                    <el-col v-if="item.checkpoints.length === 1 && item.checkpoints[0].amount"
                            :span="24"
                            class="d-flex justify-content-center"
                    >
                        <mission-icon-type type="time-management"></mission-icon-type>
                    </el-col>
                    <el-col v-else-if="item.amount_benzine || (item.checkpoints.length > 0 && item.checkpoints[0].amount_benzine)"
                            :span="24"
                            class="d-flex justify-content-center"
                    >
                        <mission-icon-type type="commodity"></mission-icon-type>
                    </el-col>
                    <el-col v-else :span="24" class="d-flex justify-content-center">
                        <mission-icon-type type="open-safe-box-with-piggy-bank"></mission-icon-type>
                    </el-col>
                </el-col>
                <el-col :lg="16" :xs="22">
                    <div class="description">
                        <div class="name mb-2">
                            {{ item.name }}
                        </div>
                        <el-row class="other">
                            <el-col v-if="item.checkpoints.length > 1">
                                <el-col v-if="item.checkpoints[0].amount_benzine">
                                    <progress-line-checkpoints :checkpoints="item.checkpoints"></progress-line-checkpoints>
                                </el-col>
                                <el-col v-else>
                                    <progress-line :value="item.mission_checkpoint_progress"></progress-line>
                                </el-col>
                                <el-col v-if="item.amount || item.amount_benzine">
                                    {{ item.mission_checkpoint_progress ? Math.round(item.mission_checkpoint_progress / 100 * item.checkpoints.length) : 0 }} з {{ item.checkpoints.length }}
                                </el-col>
                            </el-col>
                            <el-col v-else-if="item.checkpoints.length === 1">
                                <el-col>
                                    <progress-line :value="item.mission_checkpoint_progress"></progress-line>
                                </el-col>

<!--                            //TODO item.bonus_type_id === 7 UNDERDOT need refactoring-->
                                <el-col v-if="item.bonus_type_id === 7">
                                    {{ item.checkpoints[0].checkpoint_progress.length > 0 ? item.checkpoints[0].checkpoint_progress[0].count_sales : 0 }} з {{ item.value }}
                                </el-col>
                                <el-col v-else-if="item.checkpoints[0].count_sales">
                                    {{ item.checkpoints[0].checkpoint_progress.length > 0 ? item.checkpoints[0].checkpoint_progress[0].count_sales : 0 }} з {{ item.checkpoints[0].every_days ? item.checkpoints[0].every_days : item.checkpoints[0].count_sales }}
                                </el-col>
                            </el-col>
<!--                            //TODO item.bonus_type_id === 1 need refactoring-->
                            <el-col v-else-if="item.bonus_type_id === 1">
                                <el-col>
                                    <progress-line :value="item.mission_progress"></progress-line>
                                </el-col>
                                <el-col>
                                    {{ item.mission_progress ? 1 : 0 }} з 1
                                </el-col>
                            </el-col>
                            <el-col v-else-if="item.checkpoints.length === 0 && item.check_after_count_sales">
                                <el-col>
                                    <progress-line :value="item.mission_progress"></progress-line>
                                </el-col>
                                <el-col>
                                    {{ item.check_after_count_sales / 100 * item.mission_progress }} з {{ item.check_after_count_sales }}
                                </el-col>
                            </el-col>
                            <el-col v-else-if="item.checkpoints.length === 0">
                                <el-col>
                                    <progress-line :value="item.mission_progress"></progress-line>
                                </el-col>
                                <el-col>
                                    {{ item.mission_progress >= 100 ? 1 : 0 }} з 1
                                </el-col>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :lg="3" :xs="4">
                    <div v-if="!item.check_after_count_sales">
                        <div class="mission-bonus-value text-center" v-if="item.amount">
                            <img src="~@/assets/images/bonus-system/ukasko-bonus.svg">
                            <span>{{ Math.round(item.amount) }}</span>
                        </div>
                        <div class="mission-bonus-value text-center" v-else-if="item.checkpoints.length === 1">
                            <img src="~@/assets/images/bonus-system/ukasko-bonus.svg">
                            <span>{{ Math.round(item.checkpoints[0].amount) }}</span>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<style scoped>
.mission-card {
    border-radius: 5px;
    box-shadow: 0px 4px 20px 0px #00000026;

    .el-card {
        border-radius: 5px;
        .el-card__body {
            padding-left: 0;
            @media (max-width: 992px){
                padding-left: 20px;
            }
        }
    }

    .product-type-osago {
        background: rgba(255, 173, 61, 1);
        border-radius: 5px;
        color: white;
        font-size: 0.7rem;
        @media (max-width: 992px){
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            border-radius: 0 0 5px 5px;
            width: fit-content;
            padding-left: 17px;
            padding-right: 17px;
        }
    }
    .product-type-green-card {
        background: rgba(2, 169, 78, 1);
        border-radius: 5px;
        color: white;
        font-size: 0.7rem;
        @media (max-width: 992px){
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            border-radius: 0 0 5px 5px;
            width: fit-content;
            padding-left: 17px;
            padding-right: 17px;
        }
    }
    .product-type-tourism {
        background: rgba(0, 123, 255, 1);
        border-radius: 5px;
        color: white;
        font-size: 0.7rem;
        @media (max-width: 992px){
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            border-radius: 0 0 5px 5px;
            width: fit-content;
            padding-left: 17px;
            padding-right: 17px;
        }
    }
    .mission-done {
        background: rgba(118, 166, 45, 0.15);
        .mission-name {
            background: rgba(118, 166, 45, 1);
        }
    }
    .mission-block {
        background: rgba(255, 95, 0, 0.1);
        .mission-name {
            background: rgba(220, 20, 60, 1);
        }
    }
    .description {
        .name {
            font-weight: 700;
            font-family: "Circe", sans-serif;
            font-size: 1rem;
        }
        .other {
            color: #AEADAD;
            font-weight: 700;
            font-family: "Circe", sans-serif;
            font-size: 0.9rem;
            img {
                height: 1.5rem;
                width: 1.5rem;
            }
            .el-col {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .cycle {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
    }
    .number-of-completed {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }

    .bg-one-time {
        background: #009BEB!important;
    }
    .bg-cumulative {
        background: #4E5591!important;
    }

    .mission-name {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 207, 38, 1);
        border-radius: 5px 0px 5px 0px;

        span {
            color: white;
            font-weight: 700;
            font-family: "Circe", sans-serif;
            margin-left: 3px;
            margin-right: 3px;
            font-size: 1rem;
        }
    }
    .mission-bonus-value {
        margin: auto;
        span {
            color: #24282C;
            font-weight: 700;
            font-family: "Circe", sans-serif;
            font-size: 14px;
        }
    }
}
</style>
